// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../usr/local/data/service/node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../usr/local/data/service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vtex-render-runtime-8-x-lazyload.lazyload {\n  opacity: 0;\n}\n", "",{"version":3,"sources":["webpack://./react/components/LazyImages.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ","sourcesContent":[".lazyload:global(.lazyload) {\n  opacity: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lazyload": "vtex-render-runtime-8-x-lazyload"
};
module.exports = ___CSS_LOADER_EXPORT___;
