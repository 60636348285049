// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../usr/local/data/service/node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../usr/local/data/service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@-webkit-keyframes vtex-render-runtime-8-x-slide {\n  0% {\n    transform: translate3d(-50%, 0, 0);\n  }\n  100% {\n    transform: translate3d(0, 0, 0);\n  }\n}\n\n@keyframes vtex-render-runtime-8-x-slide {\n  0% {\n    transform: translate3d(-50%, 0, 0);\n  }\n  100% {\n    transform: translate3d(0, 0, 0);\n  }\n}\n\n.vtex-render-runtime-8-x-slide {\n  -webkit-animation: vtex-render-runtime-8-x-slide 1.5s infinite linear;\n          animation: vtex-render-runtime-8-x-slide 1.5s infinite linear;\n}\n", "",{"version":3,"sources":["webpack://./react/components/Preview/ContentLoader.css"],"names":[],"mappings":"AAAA;EACE;IACE,kCAAkC;EACpC;EACA;IACE,+BAA+B;EACjC;AACF;;AAPA;EACE;IACE,kCAAkC;EACpC;EACA;IACE,+BAA+B;EACjC;AACF;;AAEA;EACE,qEAAqC;UAArC,6DAAqC;AACvC","sourcesContent":["@keyframes slide {\n  0% {\n    transform: translate3d(-50%, 0, 0);\n  }\n  100% {\n    transform: translate3d(0, 0, 0);\n  }\n}\n\n.slide {\n  animation: slide 1.5s infinite linear;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slide": "vtex-render-runtime-8-x-slide"
};
module.exports = ___CSS_LOADER_EXPORT___;
