// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../usr/local/data/service/node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../usr/local/data/service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@-webkit-keyframes vtex-render-runtime-8-x-loading-bar-animation {\n  0% {\n    transform: scale(0, 1);\n  }\n  10% {\n    transform: scale(0.5, 1);\n  }\n  20% {\n    transform: scale(0.75, 1);\n  }\n  100% {\n    transform: scale(0.95, 1);\n  }\n}\n\n@keyframes vtex-render-runtime-8-x-loading-bar-animation {\n  0% {\n    transform: scale(0, 1);\n  }\n  10% {\n    transform: scale(0.5, 1);\n  }\n  20% {\n    transform: scale(0.75, 1);\n  }\n  100% {\n    transform: scale(0.95, 1);\n  }\n}\n\n.vtex-render-runtime-8-x-loadingBarAnimation {\n  transform-origin: 0 0;\n  -webkit-animation: vtex-render-runtime-8-x-loading-bar-animation 6s ease-out;\n          animation: vtex-render-runtime-8-x-loading-bar-animation 6s ease-out;\n  transform: scale(0.95, 1);\n}\n", "",{"version":3,"sources":["webpack://./react/components/LoadingBar.css"],"names":[],"mappings":"AAAA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,wBAAwB;EAC1B;EACA;IACE,yBAAyB;EAC3B;EACA;IACE,yBAAyB;EAC3B;AACF;;AAbA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,wBAAwB;EAC1B;EACA;IACE,yBAAyB;EAC3B;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,qBAAqB;EACrB,4EAA4C;UAA5C,oEAA4C;EAC5C,yBAAyB;AAC3B","sourcesContent":["@keyframes loading-bar-animation {\n  0% {\n    transform: scale(0, 1);\n  }\n  10% {\n    transform: scale(0.5, 1);\n  }\n  20% {\n    transform: scale(0.75, 1);\n  }\n  100% {\n    transform: scale(0.95, 1);\n  }\n}\n\n.loadingBarAnimation {\n  transform-origin: 0 0;\n  animation: loading-bar-animation 6s ease-out;\n  transform: scale(0.95, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingBarAnimation": "vtex-render-runtime-8-x-loadingBarAnimation",
	"loading-bar-animation": "vtex-render-runtime-8-x-loading-bar-animation"
};
module.exports = ___CSS_LOADER_EXPORT___;
